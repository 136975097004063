<template>
  <div>
    <!--begin::Trainings-->
    <div class="card card-custom gutter-b">
      <div class="card-header pt-3 pb-3">
        <div class="card-title">
          <h3 class="card-label">My Trainings List</h3>
        </div>
        <div class="card-toolbar">
          <div class="example-tools justify-content-center">
          </div>
        </div>
      </div>
      <div class="card-body">
        <form class="form" novalidate="novalidate" id="kt_training_form">
          <!--begin::Example-->
          <div class="example mb-10">
            <v-card>
              <v-card-title class="pt-0 pr-0">
                <v-spacer></v-spacer>
                <div class="col-md-4 my-2 my-md-0 pr-0">
                  <div class="input-icon">
                    <v-text-field
                      v-model="search"
                      single-line
                      placeholder="Search"
                      class="form-control"
                      hide-details
                    ></v-text-field>
                    <span>
                      <i class="flaticon2-search-1 text-muted"></i>
                    </span>
                  </div>
                </div>
              </v-card-title>
              <v-data-table 
              :headers="headers" 
              :search="search"
              :items="trainings_list"
              >
                <template v-slot:item="row">
                  <tr>
                    <td>{{ row.item.name }}</td>
                    <td>{{ row.item.training_progress }}%</td>
                    <td>
                      <v-tooltip top>
                        <template v-slot:activator="{ on }">
                          <v-btn
                            class="mx-2"
                            small
                            v-on="on"
                            @click="showTraining(row.item.id)"
                          >
                            View
                          </v-btn>
                        </template>
                        <span>View For Training</span>
                      </v-tooltip>
                    </td>
                  </tr>
                </template>
              </v-data-table>
            </v-card>
          </div>
        </form>
      </div>
    </div>
    <!--end::Trainings-->
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Vuetify from "vuetify";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import Swal from "sweetalert2";
import axios from "axios";

export default {
  name: "trainingsList",
  vuetify: new Vuetify(),
  components: {},
  data() {
    return {
      trainings_list: [],
      search: "",
      headers: [
        { text: "Name", align: "left", value: "name", width: "25%" },
        { text: "Training Progress", align: "left", value: "training_progress", width: "25%" },
        { text: "Action", sortable: false, value: "action", width: "25%" }
      ]
    };
  },
  watch: {
    search: function(newVal) {
      this.getTrainingList(newVal);
    },
  },
  computed: {
    ...mapGetters(["layoutConfig"]),
    config() {
      return this.layoutConfig();
    }
  },
  mounted() {
    // set the tab from previous
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "My Trainings" }]);
    this.getTrainingList();
  },
  destroyed() {
    localStorage.removeItem("builderTab");
  },
  methods: {
    showTraining(training_id) {
      this.$router.push({ name: "my-trainings.view", params: { id: training_id } });
    },
    getTrainingList(search_str = "") {
      let context = this;
      let api_url = "get-my-trainings";
      if (search_str) {
        api_url = "get-my-trainings?search_str=" + search_str;
      }
      axios({
        method: "get",
        url: api_url,
        baseURL: process.env.VUE_APP_ACBACK_URL
      }).then(
        function(result) {
          context.trainings_list = result.data.trainings;
        },
        function() {
          context.getTrainingList();
        }
      );
    }
  }
};
</script>
